@media print {
  @page {
      margin-top: 0;
      margin-bottom: 0;
  }
  body {
      padding-top: 16px;
      padding-bottom: 16px ;
  }
}

body {
  background:#fdfdfd;
}